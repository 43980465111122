.container-landing-page {
  background: radial-gradient(#202020, #000000);
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100vh;
}

.container-heading-primary {
  text-align: center;
}

.heading-primary--main {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
}

.heading-primary--sub {
  font-size: 2rem;
  font-weight: 100;
  margin-top: -1rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  .heading-primary--sub {
    font-size: 2rem;
    margin-top: -1.2rem;
  }
}

.heading-primary--sub-sub {
  font-size: 1.6rem;
  font-weight: 100;
  padding-right: 2.5rem;
}
@media (min-width: 320px) and (max-width: 480px) {
  .heading-primary--sub-sub {
    padding-right: 1.3rem;
  }
}

.container-shape {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 320px) and (max-width: 480px) {
  .container-shape {
    top: 68%;
    left: 50%;
    transform: translate(-50%, -68%);
  }
}

.container-shape--square {
  width: 20rem;
  height: 20rem;
  background-color: #fff;
  animation-name: moveInUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  box-shadow: 0 0 5rem 1rem rgba(28, 66, 68, 0.97) inset;
}
@media (min-width: 320px) and (max-width: 480px) {
  .container-shape--square {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0 2rem 0.5rem rgba(28, 66, 68, 0.97) inset;
  }
}

.container-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: auto;
  margin-bottom: 1rem;
}

.arrow {
  margin-bottom: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.1rem solid #fff;
  border-top: none;
  border-left: none;
  animation: arrowMoveAnimation 1.5s ease-in-out infinite;
  cursor: pointer;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}
@media (min-width: 320px) and (max-width: 480px) {
  .arrow {
    margin-bottom: 5rem;
  }
}

@keyframes arrowMoveAnimation {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  50% {
    transform: translate(0, -100%) rotate(45deg);
  }
  100% {
    transform: translate(0, 0) rotate(45deg);
  }
}
@keyframes moveInUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

