@import '../../../utils/include-media';
@import "../../../utils/colors";

.container-landing-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: radial-gradient(
      ellipse at bottom,
      $color-primary-dark,
      transparent
    ),
    radial-gradient(ellipse at top, $color-primary-dark, transparent);
}

.container-heading-primary {
  display: flex; /* make container a flex container */
  flex-direction: column; /* set flex direction to vertical */
  text-align: center;
}

.heading-primary--main {
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
  margin-top: 8rem;
}

.heading-primary--sub {
  font-size: 3.2rem;
  font-weight: 100;
  margin-top: -2rem;

  @include media("≥phone", "<=480px") {
    font-size: 2rem;
    margin-top: -1.2rem;
  }

}
.heading-primary--sub-sub {
  font-size: 1.6rem;
  font-weight: 100;
  padding-right: 2.5rem;

  @include media("≥phone", "<=480px") {
    padding-right: 1.3rem;
  }
}

.container-shape {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);


  @include media("≥phone", "<=480px") {
    top: 68%;
    left: 50%;
    transform: translate(-50%, -68%);
  }

}

.container-shape--square {
  width: 20rem;
  height: 20rem;
  background-color: $color-white;
  animation-name: moveInUp;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  box-shadow: 0 0 5rem 1rem $color-backround inset;

  @include media("≥phone", "<=480px") {
    width: 5rem;
    height: 5rem;
    box-shadow: 0 0 2rem .5rem $color-backround inset;
  }
}

.container-footer{
  display:flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: auto;
  margin-bottom: 1rem;
}

.arrow {
  margin-bottom: 2rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.1rem solid $color-white;
  border-top: none;
  border-left: none;
  animation: arrowMoveAnimation 1.5s ease-in-out infinite;
  cursor: pointer;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
  @include media("≥phone", "<=480px") {
    margin-bottom: 5rem;
  }
}

@keyframes arrowMoveAnimation {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }
  50% {
    transform: translate(0, -100%) rotate(45deg);
  }
  100% {
    transform: translate(0, 0) rotate(45deg);
  }
}

@keyframes moveInUp {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
